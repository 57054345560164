/*

Welcome to the main stylesheet - Ruler of all global styles.
This file should not contain anything but @import and comments.

SASS 7-1 PATTERN

By following the 7-1 pattern as consistently as possible, it should be easier to  
organize and maintain global style rules. 

https://sass-guidelin.es/#the-7-1-pattern 

*/

/* 
// Abstracts (setup)
The setup file gathers all abstract sass logic (variables, mixins, functions). 
Individual angular component stylesheets may import setup to access global stylesheet variables/logic.
(NO OTHER stylesheet should be imported at Angular component level - Use selectors/classnames to access global style rules)
*/

@import 'styles/setup';
@import 'styles/abstracts/variables';

// Vendors
@import 'styles/vendors/mobiscroll';
@import 'styles/vendors/pbl-mobiscroll-overrides';
@import 'styles/vendors/pbl-prime-overrides';

// Base
@import 'styles/base/reset';
@import 'styles/base/typography';

// Layout
@import 'styles/layout/body';
@import 'styles/layout/layout';

// Components
@import 'styles/components/forms';
@import 'styles/components/card';
@import 'styles/components/wizard';
@import 'styles/components/comment';
@import 'styles/components/message';
@import 'styles/components/balance';
@import 'styles/components/employee-time';

// Pages
@import 'styles/pages/my-time';
@import 'styles/pages/employee';

// Themes
/* Check folder "prime" in styles folder. Prime theme overrides are intentionally kept aside from project global styling */
