﻿$easeInOutQuart: cubic-bezier(0.77, 0, 0.175, 1);
$easeInOutQuad: cubic-bezier(0.455, 0.03, 0.515, 0.955);
$easeOutQuart: cubic-bezier(0.165, 0.84, 0.44, 1);
$easeInQuart: cubic-bezier(0.895, 0.03, 0.685, 0.22);
$easeOutCirc: cubic-bezier(0.075, 0.82, 0.165, 1);
// Defining a "default" easing
$ease: $easeInOutQuart !default;

@keyframes smoothAppearFromTop {
  0% {
    opacity: 0;
    transform: translateY(-0.25rem);
  }
  50% {
    transform: translateY(0);
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes disappear {
  to {
    height: 0;
    padding: 0;
    margin: 0;
  }
}
@keyframes appear {
  from {
    height: 0;
    padding: 0;
    margin: 0;
  }
  to {
    height: auto;
    transform: translateY(0);
  }
}
@keyframes slideFromTop {
  from {
    transform: translateY(-10px);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes slideFromLeft {
  from {
    transform: translateX(-10px);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes slideFromRight {
  from {
    transform: translateX(10px);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes activePulsating {
  0% {
    transform: scale(1);
    opacity: 0.1;
  }
  10% {
    opacity: 1;
  }
  50% {
    transform: scale(1.25);
    opacity: 0.25;
  }
  100% {
    transform: scale(1.5);
    opacity: 0.1;
  }
}
