﻿// ELEMENT SIZES
@use "sass:map";
$content-widths: (
    "extralarge":   90rem,
    "large":        57.5rem,
    "medium":       45rem,
    "small":        25rem,
    "extrasmall":   20rem
);


$content-extralarge: map.get($content-widths, "extralarge") !default;
$content-large: map.get($content-widths, "large") !default;
$content-medium: map.get($content-widths, "medium") !default;
$content-small: map.get($content-widths, "small") !default;
$content-extrasmall: map.get($content-widths, "extrasmall") !default;
