.wizard {
  width: 100%;
  max-width: 1440px;
  margin-inline: auto;
  &-content {
    padding-top: space(2);
    margin: space(2) auto 0 auto;
    max-width: rem(768);
  }
  &-footer-content {
    max-width: rem(768);
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  &-steps {
    position: sticky;
    z-index: 2;
    top: 0;
    .p-steps-title {
      @include fontsize(smaller);
    }
  }

  &-welcome {
    grid-column: 2 / -2;
    max-width: $content-large;
    @include bp(large) {
      grid-column: 4 / -4;
    }

    text-align: center;
    padding: space(2);
    &__image {
      width: clamp(100px, 50%, 200px);
      margin: space(2) auto space(3);
    }
    &__list {
      list-style-type: decimal;
      text-align: left;
      margin-left: space(2);
    }
    &__ingress {
      @include fontsize(medium);
      font-weight: 700;
      padding-bottom: space(4);
    }
  }
  .panel {
    background-color: var(--mentor-background-message);
  }
  &-step-title {
    margin-bottom: space(2);
    text-align: center;
  }
}
