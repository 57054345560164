@import '/src/styles/setup.scss';

.absence,
.overtime {
  &__start-registration {
    background-color: var(--mentor-background-panel);
    border: 1px solid var(--mentor-border-color);
    padding: space(4) space(2);
    border-radius: 4px;
    display: grid;
    grid-template-columns: 1fr 20px;
    grid-template-areas: 'title chevron' 'description chevron';
    align-items: center;
    max-width: $content-small;

    &-label {
      grid-area: title;
    }

    &-description {
      grid-area: description;
      color: var(--mentor-background-panel);
    }

    .fal {
      grid-area: chevron;
    }

    &:hover,
    &:has(:focus) {
      outline: 2px solid var(--p-primary-100);
    }
  }

  &__event-list {
    max-width: $content-medium;

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: space(2);
    }

    &-items {
      display: grid;
      gap: space(1);
      margin-bottom: space(2);
      list-style: none;
      padding: 0;
    }
  }

  .event-list-item {
    cursor: pointer;
    background-color: var(--mentor-background-panel);
    padding: space(2);
    border-radius: 4px;
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: space(1);
    position: relative;

    .panel & {
      background-color: var(--mentor-background-base);
      border: 1px solid var(--mentor-border-color);
    }

    &:hover,
    &:has(:focus) {
      outline: 2px solid var(--p-primary-100);
    }

    &__status {
      justify-self: flex-end;
      gap: space(1);
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      align-self: flex-end;
    }

    &__time {
      white-space: nowrap;
    }
    &__duration {
      justify-self: flex-end;
      .comment {
        margin-right: 1rem;
      }
    }

    &__topright {
      justify-self: flex-end;
      align-self: flex-start;

      .duration {
        justify-self: flex-end;
      }

      .comment {
        margin-right: 1rem;
      }

      .flag {
        display: inline;
        margin-right: 1rem;
      }
    }
  }
}

.new-absence-page,
.new-overtime-page {
  &__header {
    max-width: var(--content-width);
    display: flex;
    align-items: center;
    margin-bottom: space(2);
  }

  &__list {
    display: grid;
    gap: space(1);
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    align-content: stretch;
    list-style: none;
    padding: 0;

    &-link {
      display: grid;
      text-align: left;
      width: 100%;
      grid-template-columns: 1fr 20px;
      grid-template-areas: 'title chevron';
      align-items: center;
      gap: 0 space(1);
      padding: space(2);
      background: var(--mentor-background-base);
      border: 1px solid var(--mentor-border-color);
      border-radius: 4px;
      height: 100%;
      &:hover {
        outline: 2px solid var(--p-primary-100);
      }
    }
    &-title {
      grid-area: title;
    }

    &-chevron {
      grid-area: chevron;
      align-self: center;
    }
    &-description {
      grid-area: description;
    }
  }
  &__footer {
    max-width: $content-medium;
  }
}
