.my-profile {
    &-header {
      display: flex;
      gap: space(1);
      align-items: center;
      padding: space(2) space(1);
    }
  
    &-section-header {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: baseline;
      gap: space(2);
      margin-bottom: space(2);
      margin-top: space(1);

      @include bp(medium){
        flex-wrap: nowrap;
      }
  
      &.has-not-active-employments {
        display: grid;
      }
  
      h2 {
        margin-bottom: 0;
      }
  
      &-left {
        justify-content: flex-start;
      }
    }
  
    &-list {
      margin: space(1) space(-2.5) space(2) space(-2.5);
  
      @include bp(large) {
        column-count: 2;
      }
  
      li {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        gap: space(1);
        padding: space(0.5) space(2.5);
      }
  
      i {
        color: var(--red-600, red);
      }
    }
  
    &-details {
      margin: space(1) 0 space(2) 0;
  
      li {
        display: flex;
        justify-content: space-between;
      }
    }
  }
  
  .employment-header-container {
    display: flex;
    gap: 1.5rem;
    align-items: flex-end;
  }
  
  .no-children-container {
    margin: 2rem;
  }