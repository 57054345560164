@mixin focused-ring($ring-color) {
  box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px $ring-color, 0 1px 2px 0 rgba(0, 0, 0, 1);
}

$transitionDuration: 0.2s !default;

.pbl-m {
  .p-accordion {
    .p-accordion-header {
      .p-accordion-header-link {
        transition: background-color $transitionDuration, border-color $transitionDuration,
          box-shadow $transitionDuration;
      }
    }
  }

  .p-panelmenu .p-panelmenu-header {
    transition: outline $transitionDuration;

    &:focus {
      .p-panelmenu-header-link {
        background-color: var(--mentor-light-blue-100);
      }
    }
  }

  .p-button {
    &-label {
      font-weight: 400;
    }

    >i,
    >span {
      &:not(.p-button-icon-left):not(.p-button-icon-right) {

        +span,
        +i {
          margin-left: 0.5rem;
        }
      }
    }

    &.p-button-icon-only {

      &:hover {
        background: var(--mentor-teal-25);
      }
    }

    &--border-accent {
      border-color: var(--mentor-active-color);
    }
  }

  .p-panelmenu {
    a {
      display: flex;
      border-color: transparent;
      border: 1px solid transparent;
    }

    .p-panelmenu-header-link {
      font-weight: 700;
    }

    .p-panelmenu-item-link {
      gap: space(1);
      padding: 0.66rem 1rem 0.66rem 1.5rem;
    }
  }

  .p-panelmenu-header-active .p-panelmenu-header-link {
    background: var(--mentor-background-message);

    span {
      color: var(--mentor-active-color);
    }
  }

  .p-panelmenu-panel {

    .p-menuitem-icon,
    .p-panelmenu-submenu-icon {
      min-width: 1.5rem;
      display: inline-flex;
      justify-content: center;
    }

    &:has(.p-toggleable-content) {
      .p-panelmenu-header-link {
        .p-iconwrapper {
          order: 3;
        }

        .p-menuitem-icon {
          order: 1;
        }

        .p-panelmenu-header-label {
          flex-grow: 1;
          order: 2;
        }
      }
    }
  }

  .p-progress-spinner {
    width: 25px !important;
    height: 25px !important;
    margin-inline: auto;
  }

  .p-tabview {
    .p-tabview-nav {
      li {
        .p-tabview-nav-link {
          transition: background-color $transitionDuration, border-color $transitionDuration,
            box-shadow $transitionDuration;
        }
      }

      .p-tabview-ink-bar {
        z-index: 1;
        display: block;
        position: absolute;
        bottom: 0;
        height: 2px;
        transition: 500ms cubic-bezier(0.35, 0, 0.25, 1);
      }
    }

    .p-tabview-panels {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .p-carousel {
    .p-carousel-indicators .p-carousel-indicator.p-highlight button {
      background-color: $primaryColor;
    }
  }

  .p-galleria {
    .p-galleria-indicators .p-galleria-indicator.p-highlight button {
      background-color: $primaryColor;
    }
  }

  .p-inputtext {
    &:enabled:focus.ng-invalid.ng-touched {
      box-shadow: 0 0 0 0.2rem var(--mentor-rusty-red-100);
      border-color: var(--mentor-rusty-red-100);
    }

    &:disabled {
      background-color: var(--mentor-background-base);
    }
  }

  .p-inputnumber-input {
    width: 100%;
  }

  .p-inputtext {
    scroll-padding-top: 100px;
  }

  .p-error {
    color: var(--mentor-rusty-red-100, #af5e0e);
    background-color: var(--mentor-rusty-red-25, #ebd0b5);
    padding: 0.25rem 0.5rem;
    animation: smoothAppearFromTop 0.25s forwards;
    border-radius: var(--mentor-border-radius);
  }

  @keyframes animateInHeight {
    from {
      height: 0;
    }

    to {
      height: 200px;
    }
  }

  .p-panel {
    margin-bottom: space(1);

    &.p-panel-toggleable {
      margin-bottom: 0.5rem;

      &:not(.p-panel-expanded) .p-toggleable-content {
        height: 0;
      }
    }

    .p-panel-header {
      padding: 1rem 0.5rem;
      background: var(--mentor-background-base);
      color: var(--mentor-text-base);
      border-top-right-radius: 6px;
      border-top-left-radius: 6px;

      @media screen and (min-width: $small-breakpoint-value) {
        padding: 1rem 1.5rem;
      }

      .p-panel-header-icon {
        border-radius: var(--mentor-border-radius);
        border: 1px solid var(--mentor-border-color);
        height: 2.5rem;
        width: 1rem;
        margin-left: 0.25rem;

        @media screen and (min-width: $small-breakpoint-value) {
          height: 3rem;
          width: 3rem;
          margin-left: 0.5rem;
        }
      }
    }
  }

  .p-element.p-sortable-column {
    white-space: nowrap;
  }

  .p-card {
    box-shadow: none;
    border: 1px solid var(--mentor-border-color);

    &-footer {
      display: flex;
      gap: space(1);
      justify-content: flex-end;
    }
  }

  .p-multiselect-label {
    max-width: rem(200);
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .p-dialog {
    width: clamp(#{rem(320)}, 90vw, #{$content-large});
    overflow: hidden;

    .p-confirmdialog-icon {
      font-size: 2rem !important;
    }

    .full & {
      width: 100vw !important;
      height: 100vh !important;
      top: 0 !important;
      left: 0 !important;
      max-height: 100%;
    }

    .p-dialog-header {
      padding: 0.5rem 1rem;
      background: var(--mentor-background-header);
    }

    .p-dialog-footer {
      transition: all 0.5s;
      padding-top: space(2);

      &.add-shadow {
        box-shadow: rgba(0, 0, 0, 0.45) 3px -18px 20px -20px;
      }

      &--sticky {
        background: var(--p-dialog-background);
        position: sticky;
        bottom: 0;
        padding: space(2) 0;
        margin-top: space(2);
      }
    }

    .p-dialog-content {
      padding-top: 0;
      scroll-behavior: smooth;

      &:has(.p-dialog-footer--sticky) {
        padding-bottom: 0;
        overflow-x: unset;

        .p-dialog-footer--sticky{
          z-index: 2000;
        }
      }
    }

    &-maximized {
      .p-dialog-header {
        //border-bottom: 1px solid var(--mentor-border-color);
      }
    }
  }

  .fullwidth .p-inputtext,
  .fullwidth .p-select,
  .fullwidth .p-datepicker,
  .fullwidth.p-autocomplete,
  .fullwidth .p-multiselect {
    width: 100%;
    flex-grow: 1;
  }

  .p-progressbar-label {
    opacity: 0;
  }

  // Breadcumbfix for small screen

  .p-breadcrumb {
    background-color: transparent;

    @include bp(small) {
      padding: space(1);
    }

    .pi-chevron-right:before {
      content: '\2f';
    }

    ul {
      max-width: calc(100vw - #{space(4)});
    }

    ul li .p-menuitem-link .p-menuitem-text {
      white-space: nowrap;
      text-overflow: ellipsis;
      display: block;
      line-height: 1.5;
      overflow: hidden;
      width: 100%;
    }

    .p-element {
      flex-shrink: 1;
      overflow: hidden;

      &.p-breadcrumb-home {
        flex-shrink: 0;
      }
    }

    .p-element:last-child {
      flex-shrink: 0;
    }
  }

  .p-datatable .p-paginator-bottom {
    border-bottom: 0;
  }

  .p-timeline-event {
    min-height: 40px;
  }

  .p-steps {
    @include bp(small) {
      .p-steps-title {
        @include fontsize(smaller);
      }
    }

    .p-steps-item:before {
      display: none;
    }
  }

  .p-element {
    &[severity='neutral'] {
      .p-tag {
        background-color: var(--mentor-background-base);
        color: var(--mentor-text-base);
      }
    }
  }

  .app-root .p-breadcrumb {
    border-color: transparent;
  }

  .p-panelmenu {
    .main-navigation & {
      width: auto;
      min-width: 12.5rem;
    }

    &-item-link-active {
      font-weight: 700;
      background-color: var(--mentor-background-panel);

      span {
        color: var(--mentor-active-color);
      }

      .p-menuitem-badge {
        color: var(--mentor-background-base);
      }
    }
  }

  textarea.p-inputtext {
    max-width: 100%;
    min-width: 100%;
  }

  .p-tag.inactive {
    background-color: var(--mentor-background-message);
    color: var(--mentor-background-base);
  }

  .p-button-label {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .p-datepicker {
    width: 100%;
    max-width: 400px;
  }

  .header-menu .p-menuitem-text {
    display: block;
    white-space: nowrap;
  }

  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
    transition: all 0.2s;
  }

  .p-button:disabled {
    background: var(--mentor-background-base);
    color: var(--mentor-text-placeholder);
    border-color: var(--mentor-border-color);
    border-style: dashed;
  }

  .p-toggle-switch.p-toggle-switch-checked.p-disabled .p-toggle-switch-slider {
    background: var(--mentor-background-invert);

    &::before {
      text-align: center;
      font-family: 'Font Awesome 5 Pro';
      font-size: 13.5px;
      font-weight: 600;
      color: var(--mentor-background-invert);
      line-height: 1.5em;
      content: '\f023';
    }
  }

  .p-confirm-popup {
    max-width: 100%;
  }

  .p-togglebutton {
    padding: 12px 20px;
    background: #fff;
    border: 1px solid #cedad6;
    color: #495752;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;

    &.p-togglebutton-checked {
      background-color: var(--p-primary-100) !important;
      border-color: var(--p-primary-100) !important;
      color: #003b46;

      &::before {
        background-color: var(--p-primary-100) !important;
        border-color: var(--p-primary-100) !important;
        box-shadow: var(--p-primary-100) !important;
      }
    }
  }

  .p-checkbox-field {
    display: inline-flex;
    gap: 0.5rem;

    label {
      cursor: pointer;
      font-weight: 400;
    }
  }

  .p-message {
    margin: 0 0 0.5rem 0;
    border: solid;
    outline-style: none;
    border-width: 0 0 0 6px;

    &.p-message-error {
      h2 {
        color: var(--mentor-rusty-red-100);
      }
    }

    &.p-message-info {
      h2 {
        color: var(--mentor-blue-100);
      }
    }

    &.p-message-warn {
      h2 {
        color: var(--mentor-brown-100);
      }
    }
  }

  .p-listbox-list {
    .p-listbox-option-group~.p-listbox-option {
      padding: 0.5rem 0.5rem 0.5rem 2.75rem;
    }
  }

  .p-tab {
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
  }

  .p-stepper {
    .p-step {
      .p-step-header {
        font-size: 1rem;

        &.active {
          .p-step-number {
            border: 2px solid var(--mentor-active-color);
          }

          .p-step-title {
            font-weight: 600;
          }
        }

        &.done {
          .p-step-number {
            color: var(--mentor-primary-base);
            background-color: var(--mentor-green-25);
            border: 1px solid var(--mentor-primary-base);
          }

          .p-step-title {
            font-weight: 100;
            color: var(--mentor-primary-base);
          }
        }
      }
    }
  }

  .inputtext-sm {
    max-width: 3rem;
    padding: var(--p-form-field-sm-padding-y);
  }

  .p-datatable {
    .p-datatable-frozen-column[alignfrozen='right'] {
      box-shadow: -10px 0 10px -10px rgba(0, 0, 0, 0.15);
      width: calc(100vw - 100%);
    }
  }

  /*
    Datatable custom overrides
  */
  .p-datatable-table-container {
    .p-datatable-tbody {
      >tr {
        &.p-datatable-dragpoint-top>td {
          box-shadow: inset 0 2px 0 0 $primaryColor;
        }

        &.p-datatable-dragpoint-bottom>td {
          box-shadow: inset 0 -2px 0 0 $primaryColor;
        }

        &:last-child>td {
          border-bottom: 0;
        }
      }
    }
  }

  .panel .p-datatable {
    margin-left: -1rem;
    margin-right: -1rem;
  }

  .p-datatable-thead>tr>th {
    white-space: nowrap;

    .p-icon {
      margin-left: 0.25rem;
    }
  }

  /*
    ## Very custom disabled toggleswitch with lock symbol
    */
  .p-toggleswitch.p-toggleswitch-checked.p-disabled .p-toggleswitch-slider {
    .p-toggleswitch-handle::before {
      text-align: center;
      font-family: 'Font Awesome 5 Pro';
      font-size: 12.5px;
      font-weight: 600;
      line-height: 1.5em;
      content: '\f023';
      color: var(--mentor-text-placeholder);

      @media (min-width: $medium-breakpoint-value) {
        line-height: 1em;
      }

      @media (min-width: $large-breakpoint-value) {
        line-height: 1.5em;
      }

      @media (min-width: $xlarge-breakpoint-value) {
        line-height: 0.5em;
      }
    }
  }

  /*
  ## Radio/checkbox border widths are insufficient, and ineditable in Prime theming
  */
  .p-checkbox-box,
  .p-radiobutton-box {
    border-width: 1.5px;
  }

  /*
  Prevent jumps of the page with toggle of modal window
  */
  .p-overflow-hidden {
    overflow-y: scroll;
  }
}
