.manage-employee-time {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: space(1);
  }

  &__search {
    border-width: 1px 0 1px;
    border-style: solid;
    border-color: var(--mentor-border-color);
    padding: space(2) 0;
    display: flex;
    gap: space(1);
    flex-wrap: wrap;

    & > span {
      flex-grow: 1;
    }

    &-filters {
      display: flex;
      gap: space(1);
      flex-wrap: wrap;
    }
  }

  .add-button {
    align-self: end;
  }

  &__items {
    display: grid;
    gap: space(2);
    max-width: $content-medium;
    list-style: none;
    padding: 0;
  }

  &__timespan {
    display: inline-flex;
    gap: space(1);
    align-items: center;
  }
  &__item {
    background-color: var(--mentor-background-panel);
    padding: space(2);
    border-radius: 4px;
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: space(1) space(2);

    .panel & {
      background-color: var(--mentor-background-base);
      border: 1px solid var(--mentor-border-color);
    }

    &.saving {
      opacity: 0.5;
    }

    &:hover,
    &:has(:focus) {
      outline: 2px solid var(--p-primary-100);
    }

    &-footer {
      grid-column: 1 / -1;
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;
      gap: space(1);
    }

    &-status {
      justify-self: flex-end;
      align-self: center;
    }

    &-type {
      align-self: center;
      margin-bottom: 0;
    }

    &-time {
      grid-column: 1 / 1;
      display: inline-flex;
      gap: space(1);
      align-items: center;
    }

    &-duration {
      grid-column: 2 / 2;
      justify-self: flex-end;
    }

    &-icons {
      justify-self: flex-end;
      display: flex;
      gap: 1rem;
    }
  }
}
