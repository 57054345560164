.mo-comment {
  @include fontsize('small');
  margin: space(2) 0;

  &__author {
    margin: 0.5rem 0;
    display: flex;
    align-items: baseline;

    * {
      margin-right: 0.5rem;
    }
  }
  &__message {
    border-radius: var(--mentor-border-radius);
    border-top-left-radius: 0;
    background-color: var(--mentor-background-panel);
    padding: 0.5rem;
    border: 1px solid var(--shade400);
    opacity: 0.6;
  }

  &--light-text {
    color: var(--mentor-text-base-secondary);
  }
}
