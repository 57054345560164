.desktop-balance,
.balance {
  border: 1px solid var(--mentor-border-color);
  border-radius: 0.5rem;
  overflow: hidden;
}

.balance-item {
  display: grid;
  justify-items: stretch;
  grid-template-columns: 1fr 1fr;
  min-height: 3rem;
  background: var(--mentor-background-base);

  .singular-balance & {
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;

    &.has-balance {
      grid-template-columns: minmax(40%, auto) 1fr;

      .desc {
        border-right: 1px solid var(--mentor-border-color);
      }
    }
    .balance-details {
      background-color: var(--mentor-background-panel);
    }
    .balance-grid {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      > * {
        padding: 0.5rem;
      }
    }
  }
  .compact & {
    &.has-balance {
      grid-template-columns: 1fr auto;
      grid-template-areas:
        'type balance'
        'grid grid';

      .balance-details {
        grid-area: grid;
      }
      .desc {
        border-right: 0;
      }
    }
  }
  .compact-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding: 0 0.5rem;
  }

  &:not(:first-of-type) {
    border-top: 1px solid var(--mentor-border-color);
  }

  &:nth-of-type(2n) {
    background: var(--mentor-background-base);
  }

  p-skeleton {
    display: inline-flex;
    justify-self: center;
  }

  .desc {
    padding-right: 0.5rem;
    align-items: center;
    display: grid;
    grid-gap: 0.5rem;
    grid-template-columns: auto 1fr;

    p-skeleton {
      margin-left: 1rem;
    }
  }

  .balance-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    width: 100%;
    height: 100%;
    justify-items: stretch;

    &.header *::first-letter {
      text-transform: uppercase;
    }

    > div {
      border-left: 1px solid var(--mentor-border-color);
      text-align: center;
      display: grid;
      align-items: center;

      span {
        word-break: break-word;
        overflow-wrap: break-word;
        hyphens: auto;
      }
    }

    :first-of-type {
      border: 0;
    }
  }

  .single-value {
    text-align: center;
    display: grid;
    align-items: center;
    height: 100%;
    padding: 0.5rem;
  }

  .pbl-emoji {
    border-radius: 0;
  }
}

@media only screen and (max-width: #{$medium-breakpoint-value}) {
  .balance {
    border-bottom: 0;

    .balance-item {
      grid-template-columns: 1fr;

      &.has-balance {
        grid-template-columns: 1fr;
        grid-template-rows: auto auto;

        > * {
          border-bottom: 1px solid var(--mentor-border-color);
        }
      }
      .desc,
      .single-value {
        text-align: left;
        padding: space(1);
      }

      .balance-grid > div {
        padding: space(1);
        border-left: 0;
        border-bottom: 1px solid var(--mentor-border-color);
      }
      .balance-grid {
        grid-template-columns: 1fr;
      }
      .compact-info {
        font-size: small;
      }
    }
  }
  .desktop-balance {
    display: none;
  }
}
