body {
  @include establish-typography();
  scroll-behavior: smooth;
  background-color: var(--mentor-background-base);
}

html:has(.p-overflow-hidden) {
  overflow: hidden;
}

// https://stackoverflow.com/questions/9201968/content-jumps-horizontally-whenever-browser-adds-a-scrollbar
@media screen and (min-width: 960px) {
  html {
    overflow: auto;
  }
}
