h1,
.h1 {
  @include fontsize(larger);
  font-weight: 700;
  margin-bottom: space(2);
  color: var(--mentor-text-heading);
  & + span {
    display: block;
    color: var(--mentor-text-heading);
    margin-top: space(-1);
    margin-bottom: space(2);
  }
}

h2,
.h2 {
  @extend .h1;
  @include fontsize(large);
  margin-bottom: space(1);
  & + span {
    margin-top: space(-1.5);
    margin-bottom: space(1.5);
  }
}

h3,
.h3 {
  @include fontsize(medium);
  font-weight: 700;
}

h4,
.h4 {
  @include fontsize(base);
  font-weight: 700;
}
h5,
.h5 {
  @include fontsize(small);
  font-weight: 700;
}

p {
  //max-width: 70ch;
  line-height: 1.5;

  &:not(:last-child) {
    margin-bottom: space(2);
  }
}
.u-default-size {
  font-size: 1rem;
}
.u-underline {
  text-decoration: underline;
}
.u-text-right {
  text-align: right;
}
.u-text-left {
  text-align: left;
}
.u-text-center {
  text-align: center;
}
.capitalize {
  text-transform: capitalize;
}
