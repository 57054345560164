@use 'sass:math';

@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

@function rem($input-px) {
  $input-stripped: strip-unit($input-px);
  $output: math.div($input-stripped, $font-size-base) * 1rem;

  @return $output;
}

// To set up columns
@mixin grids($num: 12) {
  max-width: $content-extralarge;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  grid-template-columns: repeat($num, 1fr);
  gap: space(2);
}

// To set up subgridish columns
@mixin columns($from, $to) {
  $span: $to - $from;
  grid-column-start: $from;
  grid-column-end: $to;
  display: grid;
  grid-template-columns: repeat($span, 1fr);
  grid-gap: space(2);
}

// Mediaqueries and breakpoints setup
//***************************************
$font-size-base: 16;
$small-breakpoint-value-1: $small-breakpoint-value + math.div(1, $font-size-base) !default;
$medium-breakpoint-value-1: $medium-breakpoint-value + math.div(1, $font-size-base) !default;
$large-breakpoint-value-1: $large-breakpoint-value + math.div(1, $font-size-base) !default;
$page-max-width: 90em !default;
$breakpoint: (
  'small': screen and
    (
      max-width: #{$small-breakpoint-value},
    ),
  'medium': screen and
    (
      min-width: #{$small-breakpoint-value-1},
    ),
  'mediumonly': (
    min-width: #{$small-breakpoint-value-1},
  )
  and
  (
    max-width: #{$medium-breakpoint-value},
  ),
  'large': (
    min-width: #{$medium-breakpoint-value-1},
  ),
  'xlarge': screen and
    (
      min-width: #{$large-breakpoint-value-1},
    ),
  'mediumdown': screen and
    (
      max-width: #{$medium-breakpoint-value},
    ),
  'largedown': screen and
    (
      max-width: #{$large-breakpoint-value},
    ),
  'xlargedown': screen and
    (
      max-width: #{$xlarge-breakpoint-value},
    ),
) !default;

@mixin bp($name, $legacy: true) {
  // If the key exists in the map
  @if map-has-key($breakpoint, $name) {
    @media #{inspect(map-get($breakpoint, $name))} {
      @content;
    }
  }
}

// TYPOGRAPHY
// ***************************

// More advanced fontsize mixin

@mixin fontsize($size, $lineheight: null) {
  @if map-has-key($font-size-map, $size) {
    font-size: rem(map-get($font-size-map, $size, fontsize));
    @if $lineheight {
      line-height: rem($lineheight);
    } @else {
      line-height: rem(map-get($font-size-map, $size, lineheight));
    }
  }
  @include bp(small) {
    @if map-has-key($font-size-map-mobile, $size) {
      font-size: rem(map-get($font-size-map-mobile, $size, fontsize));
      @if $lineheight {
        line-height: rem($lineheight);
      } @else {
        line-height: rem(map-get($font-size-map-mobile, $size, lineheight));
      }
    }
  }
}

// Defining font-family
@mixin font($type: $bodytype) {
  @if map-has-key($type, font-family) {
    /* stylelint-disable font-family-name-quotes, scss/no-global-function-names */
    font-family: #{inspect(map-get($type, font-family))};
  }

  @if map-has-key($type, font-weight) {
    font-weight: map-get($type, font-weight);
  }

  @if map-has-key($type, font-style) {
    font-style: map-get($type, font-style);
  }

  @if map-has-key($type, letter-spacing) {
    @if (map-get($type, letter-spacing) != 0) {
      letter-spacing: map-get($type, letter-spacing);
    }
  }
}

// Fonts and typography
@mixin establish-typography() {
  @include font($bodytype);
  @include fontsize(base);
  color: var(--mentor-text-base);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin padding($pad: null) {
  @if $pad {
    @if ($pad == half) {
      padding: math.div($base-padding, 2);
    }
    @if ($pad == double) {
      padding: calc(#{$base-padding} * 2);
    }
    @if ($pad == quart) {
      padding: math.div($base-padding, 4);
    }
  } @else {
    padding: $base-padding;
  }
}

@mixin boxStyle($initial-space: 1, $padded: 2, $boxClass: '.panel') {
  background-color: var(--mentor-background-panel);
  border: 1px solid var(--mentor-border-color);
  box-shadow: 0 10px 4px -10px rgba(0, 0, 0, 0.1);

  @include bp(medium) {
    border-radius: var(--mentor-border-radius);
    margin-bottom: space($initial-space);
  }
  @if ($padded) {
    padding: space($padded);
    @include bp(small) {
      padding: space($padded) calc(#{space($padded)} / 2);
    }
  }
  #{$boxClass} & {
    border: 0;
    border-radius: 0;
    box-shadow: none;
    margin-top: 0;
    padding: 0;
  }
}

@function space($val: 1) {
  @return $base-padding * $val;
}

// Focus style
@mixin focusstyle {
  &:focus {
    outline: 2px solid var(--focus-ring);
  }
}

//Defining a default transition
@mixin transition($what: all, $duration: 0.2s, $easing: $easeInOutQuart) {
  transition: $what $duration $easing;
}

// Element sizes
@mixin elementsize($size: large) {
  margin-inline: auto;
  @if $size == 'extralarge' {
    max-width: $content-extralarge;
  }
  @if $size == 'large' {
    max-width: $content-large;
  }
  @if $size == 'medium' {
    max-width: $content-medium;
  }
  @if $size == 'small' {
    max-width: $content-small;
  }
}
