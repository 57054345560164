.pbl-m {
  .mbsc-ios.mbsc-calendar {
    max-height: calc(100vh - 270px);
    &.fullscreen {
      max-height: none;
    }
  }
  .absence-pending {
    .mbsc-schedule-event-background {
      background-image: linear-gradient(
        -45deg,
        #bbb 25%,
        var(--mentor-background-message) 25%,
        var(--mentor-background-message) 50%,
        #bbb 50%,
        #bbb 75%,
        var(--mentor-background-message) 75%,
        var(--mentor-background-message) 100%
      ) !important;
      background-size: 50px 50px !important;
      border: 1px solid var(--mentor-border-color);
    }
    .mbsc-schedule-event-title {
      filter: grayscale(1);
    }
  }

  .md-holiday-bg {
    background-color: rgba(255, 0, 0, 0.07);
  }
  .md-weekend-bg {
    background-color: rgba(0, 0, 0, 0.07);
  }

  .mbsc-ios.mbsc-timeline-resource-bg {
    box-shadow: 5px 0px 10px rgba(0, 0, 0, 0.05);
  }
  .mbsc-ios.mbsc-timeline-day::after,
  .mbsc-ios.mbsc-timeline-slots,
  .mbsc-ios.mbsc-timeline-slot-header,
  .mbsc-ios.mbsc-timeline-header-month,
  .mbsc-ios.mbsc-timeline-header-week,
  .mbsc-ios.mbsc-timeline-footer-week,
  .mbsc-ios.mbsc-timeline-header-date,
  .mbsc-ios.mbsc-timeline-header-column,
  .mbsc-ios.mbsc-timeline-header,
  .mbsc-ios.mbsc-timeline-footer-date,
  .mbsc-ios.mbsc-timeline-footer,
  .mbsc-ios.mbsc-timeline-footer-column,
  .mbsc-ios.mbsc-timeline-resource,
  .mbsc-ios.mbsc-timeline-resource-header-cont,
  .mbsc-ios.mbsc-timeline-resource-footer-cont,
  .mbsc-ios.mbsc-timeline-sidebar-resource,
  .mbsc-ios.mbsc-timeline-sidebar-header-cont,
  .mbsc-ios.mbsc-timeline-sidebar-footer-cont,
  .mbsc-ios.mbsc-timeline-column,
  .mbsc-ios.mbsc-timeline-row,
  .mbsc-ios.mbsc-schedule-resource-group,
  .mbsc-ios.mbsc-schedule-resource,
  .mbsc-ios.mbsc-schedule-all-day-item::after,
  .mbsc-ios.mbsc-schedule-column,
  .mbsc-ios.mbsc-schedule-item,
  .mbsc-ios.mbsc-timeline-row-group::after,
  .mbsc-ios.mbsc-timeline-row-date {
    border-color: rgba(0, 0, 0, 0.08);
  }
  .mbsc-timeline-event-background {
    margin-top: 2px;
    background: #a77543;
    border: 1px solid rgba(0, 0, 0, 0.25);
  }
  .mbsc-schedule-event-title {
    font-weight: 400;
  }
  .mbsc-schedule-event-title,
  .mbsc-timeline-resource-title {
    color: var(--mentor-text-soft);
  }
}
