// Layout grid
.app-root {
  width: 100%;
  min-height: 100dvh;
  margin-inline: auto;
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    'header'
    'main'
    'footer';

  .p-overflow-hidden & {
    overflow: hidden;
  }

  .main-area-grid {
    display: grid;
    grid-gap: space(1);
    grid-template-columns: minmax(250px, max-content) 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: 'main main';
  }

  &.app-root--hasMenu .main-area-grid {
    grid-template-columns: minmax(250px, max-content) minmax(320px, 1fr);
    grid-template-areas: 'nav main';
  }
  .layout-top-navigation {
    background-color: var(--mentor-primary-light, #eee);
  }
  .layout-header-content {
    margin-bottom: space(1);
    background-color: var(--mentor-primary-base);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);

    &.mentor-admin {
      background-color: var(--mentor-brown-100);
    }
  }
  .layout-footer-content {
    background: var(--mentor-background-panel);
    border-top: 1px solid var(--mentor-border-color);
    padding: space(2);
    margin-top: space(1);
    box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.05);
  }

  &__header {
    grid-area: header;
    position: relative;
  }

  &__main {
    grid-area: main;

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  &__footer {
    grid-column: 1 / -1;
  }
}
.pbl-simple-grid {
  display: grid;
  gap: space(2);
}

.pbl-grid {
  gap: space(2);
  &.small-gap {
    gap: space(1);
  }
  @include bp(medium) {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
  }
  @include bp(large) {
    grid-template-columns: repeat(12, 1fr);
  }
  > * {
    position: relative;

    .p-error {
      position: absolute;
      width: 100%;
      top: calc(100% - 0.25rem);
      z-index: 9;
      border-top-left-radius: 0;
    }
  }
  &-small {
    grid-column: span 2;
    @include bp(large) {
      grid-column: span 2;
    }
  }
  &-medium {
    grid-column: span 3;
    @include bp(large) {
      grid-column: span 4;
    }
  }
  &-medium-large {
    grid-column: span 12;
  }
  &-large {
    grid-column: span 6;
  }

  &-larger {
    grid-column: 1 / -1;
    @include bp(large) {
      grid-column: span 8;
    }
  }

  &-largest {
    grid-column: 1 / -1;
  }

  &-tall {
    grid-row: span 2;
  }
  &-pushdown {
    grid-column-start: 1;
  }
  > hr {
    width: 100%;
    grid-column: 1 / -1;
    margin: 0;
    border: 1px solid var(--mentor-background-base);
  }
}

.u-flex {
  display: flex;
  align-items: center;
  gap: space(1);
  &-end {
    justify-content: flex-end;
  }
}
.layout-centered-content {
  > * {
    display: block;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: $content-extralarge;

    @include bp(xlargedown) {
      padding-left: space(1);
      padding-right: space(1);
    }
  }
}

.p-frozen-column[alignfrozen='right'] {
  box-shadow: -10px 0 10px -10px rgba(0, 0, 0, 0.1);
  width: calc(100vw - 100%);
}

.page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: space(1);
  padding-bottom: space(2);
  gap: space(1);
  h1 {
    margin-bottom: 0;
    flex-grow: 1;
  }
}
.page:not(.panel) > .page-header {
  padding: space(1);
}
.bounding-box {
  display: block;
  position: relative;
}
// TODO: denne kan slettes når vi har fikset alle steder som bruker denne
.sticky-footer {
  background-color: var(--mentor-background-panel);
  position: sticky;
  bottom: 0;
  padding: space(1);
  display: flex;
  justify-content: flex-end;
  gap: space(1);
  border: 1px solid var(--mentor-border-color);
  margin-top: space(1);
  border-radius: var(--mentor-border-radius);
  box-shadow: 0 10px 4px -10px rgba(0, 0, 0, 0.1);
  .panel & {
    box-shadow: none;
    border: 0;
  }
}

.sticky-footer-compact {
  background-color: var(--mentor-background-panel);
  position: sticky;
  bottom: 0;
  padding: space(1);
  display: grid;
  gap: space(1);
  border: 1px solid var(--mentor-border-color);
  margin-top: space(1);
  border-radius: var(--mentor-border-radius);
  box-shadow: 0 10px 4px -10px rgba(0, 0, 0, 0.1);
  .panel & {
    box-shadow: none;
    border: 0;
  }
}

.scrollable-dialog {
  .p-dialog-header {
    padding: 0.3rem;
    background: var(--mentor-background-panel);
  }
}
.panel {
  @include boxStyle();

  &.tab-panel {
    border-top: 0;
    border-radius: 0 0 var(--mentor-border-radius) var(--mentor-border-radius);
  }
}
.surface-b-content {
  border-radius: var(--mentor-border-radius);
  background-color: var(--mentor-background-base);
  border: 1px solid var(--mentor-border-color);
}
.horizontal-scroll-content {
  max-width: 100%;
  overflow-x: auto;
}
// Simple reusable classes for elements to be shown/hidden only,
// for specific screen widths
.mobile-hidden {
  @include bp(small) {
    display: none !important;
  }
}
.mobile-only {
  @include bp(medium) {
    display: none !important;
  }
}
.tablet-only {
  @include bp(small) {
    display: none !important;
  }
  @include bp(large) {
    display: none !important;
  }
}
.tablet-hidden {
  @include bp(mediumonly) {
    display: none !important;
  }
}
.desktop-only {
  @include bp(largedown) {
    display: none !important;
  }
}
.desktop-hidden {
  @include bp(large) {
    display: none !important;
  }
}

.read-max {
  max-width: 768px;
}
// This class applies max width (as defined in $content-widths) on first level descendants (.content-large, .content-medium, ++)
@each $name, $width in $content-widths {
  .content-#{$name} {
    display: block;
    max-width: $width;
    padding: 15px;
  }
  .max-content-#{$name} {
    max-width: $width;
  }
}
