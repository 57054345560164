@import '../setup.scss';


$mbsc-ios-accent: #006072;      // Accent color 007bff
$mbsc-ios-background: #f7f7f7;  // Background color
$mbsc-ios-text: #000000;        // Text color

$mbsc-ios-dark-accent: #ff8400;      // Dark Accent color
$mbsc-ios-dark-background: #000000;  // Dark Background color
$mbsc-ios-dark-text: #ffffff;        // Dark Text color

$mbsc-ios-primary: #3ff6e1;     // Primary color 3f97f6
$mbsc-ios-secondary: #90979e;   // Secondary color
$mbsc-ios-success: #43be5f;     // Success color
$mbsc-ios-danger: #f5504e;      // Danger color
$mbsc-ios-warning: #f8b042;     // Warning color
$mbsc-ios-info: #5bb7c5;        // Info color
$mbsc-ios-light: #ffffff;       // Light color
$mbsc-ios-dark: #47494a;        // Dark color


@import './@mobiscroll/angular/dist/css/mobiscroll.scss';
@import 'pbl-mobiscroll-overrides';