.mo-message {
  padding: space(2);
  background-color: var(--mentor-background-panel);
  //order: 1px solid var(--mentor-border-color);
  border-radius: var(--mentor-border-radius);

  .panel &,
  .mo-message & {
    background-color: var(--mentor-background-base);
  }
  &__title {
  }
  &__desc {
    &,
    p {
      @include fontsize(small, 20px);
    }
  }
}
