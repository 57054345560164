legend {
  font-weight: 700;
  padding-left: 0;

  + p-checkbox {
    margin-top: space(1);
  }
}

fieldset {
  margin: space(2) 0;

  legend {
    //font-weight: normal;
    width: 100%;
    grid-column: 1 / -1;
    @include bp(medium) {
      float: left;
    }
  }
  fieldset {
    legend {
      clear: both;
    }
  }
}

input {
  &[type='text'],
  &[type='tel'],
  &[type='number'] {
    width: 100%;
  }
}

input {
  &[type='file'] {
    &::file-selector-button {
      color: #ffffff;
      background: #398075;
      border: 1px solid #398075;
      padding: 0.75rem 1.25rem;
      font-size: 1rem;
      transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
      /* border-radius: 6px; */
      &:hover {
        background: var(--p-primary-800, #2563eb);
        color: #ffffff;
        border-color: var(--p-primary-800, #2563eb);
      }
    }
  }
}

.radiobuttoncontainer {
  display: flex;
  flex-direction: column;
  margin: 0.5rem 0;
  > * {
    margin-right: 0.5rem;
  }
}

label {
  font-weight: 700;
  &::first-letter {
    text-transform: uppercase;
  }
  .p-checkbox + & {
    font-weight: normal;
  }
}

.pbl-radio,
.pbl-checkbox {
  &-item {
    display: flex;
    align-items: center;
    gap: space(1);
  }

  &-group {
    clear: both;

    &--horizontal {
      margin-bottom: space(2);
      display: flex;
      flex-wrap: wrap;
      gap: space(2);
    }
  }
}

.pbl-input {
  &-text {
    margin: space(1) 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: space(1);

    &:has([required]) {
      >label:after {
        content: '*';
        margin-left: 2px;
        color: var(--red-600);
      }
      mo-info-mini button {
        margin-left: 10px;
      }
    }
    input:focus {
      scroll-margin: 100px 0 200px 0;
    }
  }
}
