.card {
  @include boxStyle(0);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: visible;
  text-align: center;
  justify-content: flex-start;

  &__title {
    @include fontsize(large);
    font-weight: 700;
  }

  &__action {
    margin-top: space(2);
  }

  &__label {
    position: absolute;
    top: space(1);
    right: space(1);
    background-color: var(--red-200);
    padding: space(0.5);
    border-radius: 4px;
    @include fontsize(smaller);
  }

  &__modules {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: space(1);
    width: 100%;
    margin: space(2);
    padding-top: space(1);
    border-top: 1px solid var(--mentor-background-base);
  }

  @include bp(medium) {
    &--large {
      grid-column: span 2;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: space(2);
      & > div {
        flex-basis: 50%;
      }
    }
  }

  &--highlight {
    border-top-color: var(--red-200);
    border-top-width: 5px;
  }

  &__image {
    max-width: 100%;
    display: block;
    margin-left: space(-2);
    margin-right: space(-2);
  }
  &--unpadded {
    padding: 0;
  }
}
