@use 'sass:map';
@use 'sass:list';
@use 'sass:math';
@use 'sass:color';

// Mix color with white
@function whiten($color, $amountOfColor) {
  @return mix($color, #fff, $amountOfColor * 1%);
}
// Mix color with black
@function blacken($color, $amountOfColor) {
  @return mix($color, #000, $amountOfColor * 1%);
}

$emojiBg: linear-gradient(
    139deg,
    rgba(255, 255, 255, 0.5) 0%,
    rgba(255, 255, 255, 0.24) 51.09%,
    rgba(255, 255, 255, 0) 100%
  ),
  #d9d9d9;

$mentorPlusColors: (
  'green': #07575b,
  'blue': #003b46,
  'teal': #66a5ad,
  'light-blue': #c4dfe6,
  'brown': #867666,
  'tan': #d5c3aa,
  'orange': #8e700b,
  'rusty-red': #aa5c0e,
  'sand': #eae2d6,
  'yellow': #e1cb81,
  'pink-red': #af0e3e,
  'purple': #6a0572,
  'light-pink': #e895ae,
  'light-green': #8fc8c3,
  'grey': #5a5a5a,
);

$backgroundColors: (
  'base': #f7f7f7,
  'panel': #fff,
  'message': #eee,
  'header': #fafafa,
  'invert': #222,
);

$textColors: (
  'base': #222,
  'heading': #333,
  'soft': #444,
  'placeholder': #666,
  'invert': #eee,
  'link': map.get($mentorPlusColors, 'blue'),
);

$primaryColor: map.get($mentorPlusColors, 'green');
$primaryColors: (
  'base': $primaryColor,
  'light': whiten($primaryColor, 25),
  'dark': blacken($primaryColor, 50),
  'darker': blacken($primaryColor, 33),
  'contrast': #fff,
);

$activeColor: #006072;
$activeBg: #c4dfe6;

$borderRadius: 7px;
$borderColor: #ddd;
$borderWidth: 1px;
$border: $borderWidth solid $borderColor;

// Mentor+ colors

/*
## Outputting variable list to root css variables
*/
@mixin sassListToRootVariables($list, $variablePrefix) {
  $listNames: map.keys($list);
  @for $i from 1 through list.length($listNames) {
    $currentName: list.nth($listNames, $i);
    --mentor-#{$variablePrefix}-#{$currentName}: #{map.get($list, $currentName)};
  }
}

// Theme variables
:root {
  --mentor-border-color: #{$borderColor};
  --mentor-border: #{$border};
  --mentor-border-radius: #{$borderRadius};
  --mentor-emoji-bg: #{$emojiBg};
  --mentor-active-bg: #{$activeBg};
  --mentor-active-color: #{$activeColor};
  --mentor-warning-color: var(--mentor-orange-100, #8e700b);
  --mentor-warning-bg: var(--mentor-yellow-25, #f7f2df);
  --mentor-danger-color: var(--mentor-rusty-red-100, #af5e0e);
  --mentor-danger-bg: var(--mentor-rusty-red-25, #ebd6c2);

  @include sassListToRootVariables($backgroundColors, 'background');
  @include sassListToRootVariables($textColors, 'text');
  @include sassListToRootVariables($primaryColors, 'primary');
}
// Mentor UI primitive color variables

$mentorPlusColorNames: map.keys($mentorPlusColors);
:root {
  @for $i from 1 through list.length($mentorPlusColorNames) {
    $currentColorName: list.nth($mentorPlusColorNames, $i);
    $currentColor: map.get($mentorPlusColors, $currentColorName);

    @for $j from 1 through 4 {
      $currentShadeValue: 100 - (math.div(100, 4) * $j) + math.div(100, 4);
      --mentor-#{$currentColorName}-#{$currentShadeValue}: #{whiten($currentColor, $currentShadeValue)};
    }
    --mentor-#{$currentColorName}-10: #{whiten($currentColor, 10)};
  }
}
/* 
###################################
######## Variables in dark mode
#####################################
*/
$darkMode_backgroundColors: (
  'base': #111,
  'panel': #000,
  'message': #222,
  'invert': #eee,
  'header': #333,
);

$darkMode_textColors: (
  'base': #eee,
  'heading': #ccc,
  'soft': #bbb,
  'placeholder': #aaa,
  'invert': #111,
);

$darkMode_primaryColor: map.get($mentorPlusColors, 'green');

$darkMode_primaryColors: (
  'base': blacken($primaryColor, 50),
  'light': blacken($primaryColor, 25),
  'dark': whiten($primaryColor, 75),
  'darker': whiten($primaryColor, 50),
  'contrast': #fff,
);

$darkMode_emojiBg: linear-gradient(
    139deg,
    rgba(48, 48, 48, 0.5) 0%,
    rgba(19, 19, 19, 0.24) 51.09%,
    rgba(0, 0, 0, 0) 100%
  ),
  #272727;

$darkMode_activeColor: #c4dfe6;
$darkMode_activeBg: #006072;

$darkMode_borderColor: #555;
$darkMode_border: $borderWidth solid $darkMode_borderColor;

.mentor-darkmode {
  @include sassListToRootVariables($darkMode_backgroundColors, 'background');
  @include sassListToRootVariables($darkMode_textColors, 'text');
  @include sassListToRootVariables($darkMode_primaryColors, 'primary');

  --mentor-border-color: #{$darkMode_borderColor};
  --mentor-border: #{$darkMode_border};
  --mentor-emoji-bg: #{$darkMode_emojiBg};
  --mentor-active-bg: #{$darkMode_activeBg};
  --mentor-active-color: #{$darkMode_activeColor};

  @for $i from 1 through list.length($mentorPlusColorNames) {
    $currentColorName: list.nth($mentorPlusColorNames, $i);
    $currentColor: map.get($mentorPlusColors, $currentColorName);

    @for $j from 1 through 4 {
      $currentShadeValue: 100 - (math.div(100, 4) * $j) + math.div(100, 4);
      --mentor-#{$currentColorName}-#{$currentShadeValue}: #{blacken($currentColor, $currentShadeValue)};
    }
    --mentor-#{$currentColorName}-10: #{blacken($currentColor, 10)};
  }
}
